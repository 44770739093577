<template>
	<el-dialog v-dialogDrag :title="param.id?'修改事件类型':'新增事件类型'" :visible.sync="visible" width="600px" :before-close="close" @open="init">
		<el-form :model="param" ref="form" label-width="100px">
			<el-form-item label="类型名称" prop="typeName">
				<el-input v-model="param.typeName" maxLength="15"></el-input>
			</el-form-item>
			<el-form-item label="上级类型" prop="pid">
				<el-cascader v-model="pid" :options="treeA" @change="treeChange" :show-all-levels="false"
					:props="{checkStrictly:true,label:'typeName',children: 'children',value:'id'}" clearable>
				</el-cascader>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit()" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				param: {
					typeName: null,
					pid: null
				},
				pid: null,
				showPid: null,
				btnLoading:false,
				treeA:[],
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			tree: {
				type: Array,
				default: () => {
					return []
				},
			},
			value: {
				type: Object,
				default: () => {
					return {}
				},
			}
		},
		computed: {
			visible: {
				get() {
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {
			// value(n, o) {
			// 	if(this.value){
			// 		this.param = JSON.parse(JSON.stringify(this.value));
			// 		this.pid = this.param.pid
			// 	}
			// }
		},
		created() {},
		mounted() {},
		methods: {
			init(){
				this.treeA=JSON.parse(JSON.stringify(this.tree))
				if(Object.keys(this.value).length>0){
					this.param = JSON.parse(JSON.stringify(this.value));
					this.pid = this.param.pid
				}else{
					this.pid=this.$parent.pageParam.incidentTypeId;
					this.param.pid=this.pid
				}
				this.dealTree(this.param.id,this.treeA)
			},
			// 处理tree
			dealTree(id=null,tree) {
				tree.forEach((v) => {
					if (v.children&&v.children.length > 0) {
						this.dealTree(id,v.children)
					} else {
						v.children = null
					}
				})
				if(id){
					this.dealTreeType(id,tree)
				}
			},
			// 处理 tree 上一级不能为自己
			dealTreeType(id, tree) {
				let bool = false
				tree.forEach((v) => {
					if (bool) return
					if (v.id == id) {
						v.disabled = true;
						v.children = null
						bool = true;
					} else if (v.children && v.children.length > 0) {
						this.dealTreeType(id, v.children)
					}
				})
			},
			treeChange(e) {
				this.param.pid = e[e.length - 1]
			},
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 提交
			async submit() {
				if (!this.param.typeName) {
					this.$message.warning("请输入类型名称！");
					return
				}
				this.param.pid = this.param.pid ? this.param.pid : "0";
				this.btnLoading=true;
				let res
				if(this.param.id){
					res=await this.$put('/platform-config/incidenttype',this.param)
				}else{
					res = await this.$post('/platform-config/incidenttype', this.param);
				}
				this.btnLoading=false;
				if (res && res.code == 0) {
					if(this.param.id){
						this.$message.success("修改成功！");
					}else{
						this.$message.success("新增成功！");
					}
					this.$emit('update:show', false);
					this.$parent.getTree()
				}
			}
		},
	};
</script>

<style scoped></style>
