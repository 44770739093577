<template>
  <div class="warp">
    <div class="left">
      <div class="tabs">
        <div @click="switchTabIndex()" :class="{active:pageParam.incidentStatus!=0&&pageParam.incidentStatus!=1}">全部</div>
        <div @click="switchTabIndex(true,1)" :class="{active:pageParam.incidentStatus==1}">启用</div>
        <div @click="switchTabIndex(true,0)" :class="{active:pageParam.incidentStatus==0}">禁用</div>
      </div>
      <div class="addBtnBox">
        <el-button class="add" type="primary" icon="el-icon-circle-plus" size="small" v-if="isAuth('platform-config:incidenttype:save')" @click="openType()">
          新增类型</el-button>
      </div>
      <div class="tree-container">
        <div class="all" @click="activeAllFun" :class="{active:activeAll}">全部</div>
        <el-tree highlight-current class="sroll tree" :indent="0" :highlight-current="!activeAll" :data="data" @node-click="clickNode" :props="{label:'typeName',children: 'children'}">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div class="mr20"><span>{{ node.label }}</span></div>
            <span>
              <img @click.stop="openType(data)" v-if="isAuth('platform-config:incidenttype:update')" src="@/assets/img/icon/icon_edit.png" alt="">
              <img @click.stop="del(data)" v-if="isAuth('platform-config:incidenttype:delete')" class="ml10" src="@/assets/img/icon/icon_delete.png" alt="">
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="right">
      <jf-layout>
        <template slot="head">
          <div class="flex_bet">
            <div>
              <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:incident:save')" @click="openEvent">新增事件
              </el-button>
              <el-button size="small" type="primary" icon="el-icon-upload2" v-if="isAuth('platform-config:incident:import')" @click="$router.push('/setup-event-import')">批量导入</el-button>
              <el-button size="small" type="primary" icon="el-icon-download" v-if="isAuth('platform-config:incident:export')" @click="down">导出</el-button>
              <!-- <el-button size="small" type="danger" icon="el-icon-delete" @click="delRow()">删除</el-button> -->
            </div>
            <el-form :inline="true">
              <el-form-item>
                <el-input size="small" v-model="pageParam.incidentName" placeholder="请搜索事件名称" clearable>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="small" class="ml10" type="primary" icon="el-icon-search" @click="getList('reset')">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="hint">
            <div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
            <div>
              <el-button size="mini" type="success" v-if="isAuth('platform-config:incident:start')" :disabled="selectArr.length<=0" @click="start()">启用
              </el-button>
              <el-button size="mini" type="warning" v-if="isAuth('platform-config:incident:disable')" :disabled="selectArr.length<=0" @click="disable()">禁用
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:incident:delete')" :disabled="selectArr.length<=0" @click="delRow()">删除</el-button>
            </div>
          </div>
        </template>

        <el-table slot="body" border :data="list" v-loading="tableLoading" @selection-change="getSelect">
          <el-table-column align="center" type="selection" width="50" />
          <el-table-column align="center" prop="incidentName" label="事件名称" />
          <el-table-column align="center" prop="incidentDesc" label="事件说明" />
          <el-table-column align="center" prop="incidentTypeName" label="事件类型" />
          <el-table-column align="center" label="A分">
            <template slot-scope="{row}">
              {{row.aMin||0}}~{{row.aMax||0}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="B分">
            <template slot-scope="{row}">
              {{row.bMin||0}}~{{row.bMax||0}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="产值">
            <template slot-scope="{row}">
              {{row.outputMin||0}}~{{row.outputMax||0}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="incidentStatus" label="状态">
            <template slot-scope="{row}">
              <el-tag type="success" v-if="row.incidentStatus==1">启用</el-tag>
              <el-tag type="warning" v-if="row.incidentStatus==0">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="180">
            <template slot-scope="{row}">
              <el-button type="text" size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:incident:update')" @click="openEvent(row)">编辑
              </el-button>
              <el-button class="c-danger-imp" type="text" v-if="isAuth('platform-config:incident:delete')" size="mini" icon="el-icon-delete" @click="delRow(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" @change="getList()" />
      </jf-layout>
      <AddType :show.sync="addTypeShow" :tree="data" :value="typeVal" />
      <AddEvent :show.sync="addEventShow" :tree="data" :id="id" />
    </div>
  </div>
</template>
<script>
import AddType from "./addType";
import AddEvent from "./addEvent";
export default {
  components: {
    AddType,
    AddEvent,
  },
  data() {
    return {
      addTypeShow: false,
      addEventShow: false,
      data: [],
      pageParam: {
        page: 1,
        limit: 10,
        incidentStatus: null, //0  禁用  1启用
      },
      total: 0,
      list: [],
      id: null,
      tableLoading: false,
      selectArr: [],
      activeAll: true, //是否点击全部
      typeVal: {},
    };
  },
  created() {
    this.getTree();
  },
  methods: {
    // 切换tabs
    switchTabIndex(bool, val) {
      if (bool) {
        this.pageParam.incidentStatus = val;
      } else {
        this.pageParam.incidentStatus = null;
      }
      this.getList("reset");
    },
    // 获取事件类型
    async getTree() {
      let res = await this.$get("/platform-config/incidenttype/tree");
      if (res && res.code == 0) {
        this.data = res.data;
        this.dealTree(this.data);
        this.getList();
      }
    },
    // 处理tree
    dealTree(tree) {
      tree.forEach((v) => {
        if (v.children && v.children.length > 0) {
          this.dealTree(v.children);
        } else {
          v.children = null;
        }
      });
    },
    down() {
      this.$get(
        "/platform-config/incident/export/platformEventLibraryInformation"
      ).then((res) => {
        if (res.data && res.data.url) window.open(res.data.url);
      });
    },
    //处理回显的事件类型
    dealIncidentTypeId(id, data) {
      let name;
      data.map((v) => {
        if (name) return;
        if (v.id == id) {
          name = v.typeName;
          return;
        } else if (v.children && v.children.length > 0) {
          name = this.dealIncidentTypeId(id, v.children);
        }
      });
      return name;
    },
    // 获取列表
    async getList(reset) {
      this.tableLoading = true;
      if (reset) {
        this.pageParam.page = 1;
      }
      let res = await this.$get(
        "/platform-config/incident/page",
        this.pageParam
      );
      this.tableLoading = false;
      if (res && res.code == 0) {
        this.list = res.data.list;
        this.list.forEach((v) => {
          v.incidentTypeName =
            this.dealIncidentTypeId(v.incidentTypeId, this.data) ||
            v.incidentTypeId;
        });
        this.total = res.data.total;
      }
    },
    // 打开类型编辑弹窗
    openType(data) {
      this.addTypeShow = true;
      if (data) {
        this.typeVal = data;
      } else {
        this.typeVal = {};
      }
    },
    // 删除 类型
    async del(data) {
      this.$confirm("确定执行删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$del(`/platform-config/incidenttype/${data.id}`);
          if (res && res.code == 0) {
            this.$message.success("删除成功");
            this.getTree();
          }
        })
        .catch(() => {});
    },
    // 打开新增  编辑弹窗
    openEvent(row) {
      this.addEventShow = true;
      this.id = row ? row.id : null;
    },
    // 选择
    getSelect(e) {
      this.selectArr = e;
    },
    // 点击选择全部
    activeAllFun() {
      this.activeAll = true;
      this.pageParam.incidentTypeId = null;
      this.getList();
    },
    // tree 点击
    clickNode(e) {
      this.pageParam.incidentTypeId = e.id;
      this.activeAll = false;
      this.getList();
    },
    // 启用
    start(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      } else {
        this.selectArr.map((v) => {
          ids.push(v.id);
        });
      }
      this.$confirm("确定执行启用操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$put("/platform-config/incident/start", ids);
          if (res && res.code == 0) {
            this.$message.success("启用成功！");
            this.getList();
          }
        })
        .catch(() => {});
    },
    // 禁用
    disable(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      } else {
        this.selectArr.map((v) => {
          ids.push(v.id);
        });
      }
      this.$confirm("确定执行禁用操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$put("/platform-config/incident/disable", ids);
          if (res && res.code == 0) {
            this.$message.success("禁用成功！");
            this.getList();
          }
        })
        .catch(() => {});
    },
    // 删除
    delRow(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      } else {
        this.selectArr.map((v) => {
          ids.push(v.id);
        });
      }
      this.$confirm("确定执行删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.tableLoading = true;
          let res = await this.$del("/platform-config/incident", ids);
          this.tableLoading = false;
          if (res && res.code == 0) {
            this.$message.success("删除成功！");
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  justify-content: space-around;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  cursor: pointer;

  .active {
    color: #409eff;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 60px;
      height: 2px;
      border-radius: 1px;
      background: #409eff;
      position: absolute;
      left: -16px;
    }
  }
}

.warp {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  display: flex;

  .left {
    width: 300px;
    border-right: 1px solid #eee;
    padding: 20px;

    .add {
      margin-top: 3px;
    }
  }

  .right {
    flex: 1;
    padding: 20px;
    overflow: auto;
  }
}

.tree-container {
  margin-top: 20px;
  overflow: hidden;

  > .all {
    padding: 0 23px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
  }

  .all:hover {
    background-color: #f5f7fa;
  }

  .active {
    background-color: #f5f7fa;
  }
}

.tree {
  overflow: auto;
  height: 600px;
}

/deep/ .el-tree > .el-tree-node {
  min-width: 100%;
  display: inline-block;
}

.tree /deep/ .el-tree-node__children {
  padding-left: 16px;
}

.tree /deep/ .el-tree-node :last-child:before {
  height: 12px;
}

.tree /deep/ .el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
  border-top: none;
}

.tree /deep/ .el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
  border-left: 1px dashed #999;
  bottom: 0px;
  height: 100%;
  top: 0px;
  width: 1px;
}

.tree /deep/ .el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
  border-top: 1px dashed #999;
  height: 20px;
  top: 12px;
  width: 10px;
}

.tree /deep/ {
  .el-tree-node__content {
    height: 40px !important;
    border-bottom: 1px solid #eee;
  }
}

.ml10 {
  margin-left: 10px;
}

.hint {
  display: flex;
  justify-content: space-between;
  background: #fff4e3;
  border-radius: 5px;
  padding: 0 17px;
}

.custom-tree-node {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ml10 {
  display: inline-block;
  margin-left: 10px;
}

.mr20 {
  margin-right: 20px;
}
.addBtnBox {
  text-align: center;
}
</style>
